import { isTouchDevice } from '../../util/touchDevice';

$(document).on('turbolinks:load', function () {
  var $userDropdownToggle = $('.js-user-dropdown-toggle')
  var $userDropdownMenu = $('.user-dropdown-menu')
  var hideDropdownTimeout;

  function setupUserDropdownMouseEvents() {
    $userDropdownToggle.on('mouseover', function(){
      clearTimeout(hideDropdownTimeout);
      $userDropdownMenu.dropdown('show')
    });

    $userDropdownToggle.on('mouseleave', function(e){
      hideDropdownTimeout = setTimeout(function(){
        var relatedTarget = e.relatedTarget;
  
        if (!relatedTarget || (!$(relatedTarget).closest($userDropdownToggle).length)) {
          $userDropdownMenu.dropdown('hide');
        }
      }, 150);
    })
  }

  if (!isTouchDevice() && !window.location.pathname.includes('/admin')){
    setupUserDropdownMouseEvents();
  }

  $userDropdownMenu.on('click', function(){ 
    $userDropdownMenu.dropdown('hide');
  })
});
